import { LogLevel } from "@azure/msal-browser";


export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_CLIENT_ID,
    // authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MS_TENANT_ID}`,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${window.location.origin}/login/ms`,
  },
  cache: {
    cacheLocation: "localStorage",
    // storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
    loggerCallback: (level, message, containsPii) => {
        if (containsPii) { return; };
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    'Calendars.Read.Shared',
    'Calendars.Read',
    'email',
    'Mail.Read',
    'Mail.Read.Shared',
    'offline_access',
    'openid',
    'profile',
    'User.Read'
  ],
};
