import OutlookLogo from '../assets/images/outlook_banner.jpg';


const LoginSuccessMS = () => {
  return (
    <section className='sign-in-success'>
      <img alt="Microsoft graph for outlook" src={OutlookLogo} />
    </section>
  );
};

export default LoginSuccessMS;
